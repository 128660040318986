<script src="../../lib/debounce.js"></script>
<template>
  <div class="MaKuKuApp">
    <div class="img_box">
      <img src="https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/img_android.png"
           alt="" class="img_android">
    </div>
    <div class="img_box">
      <div class="img_box">
        <div class="btn" @click="DownClick('Android')">Android Down</div>
      </div>
    </div>

    <div class="img_box">
      <img src="https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/img_ios.png" alt=""
           class="img_ios">
    </div>
    <div class="img_box">
      <div class="btn IOS_btn" @click="DownClick('iOS')">iOS Down</div>
    </div>
  </div>
</template>

<script>
import {Toast} from "vant";

export default {
  methods: {
    DownClick(type) {
      var ua = navigator.userAgent.toLowerCase();
      let href = 'itms-apps://itunes.apple.com/cn/app/1643076952'; // 默认ios
      let model = ua.indexOf('android') != -1 ? 'Android' : 'iOS'
      if (type !== model) {
        Toast(`Please download ${type === 'iOS' ? 'Android' : 'iOS'}`);
        return
      }
      if (type === 'Android') {
        href = 'https://sgp1670896309-1305473623.cos.ap-singapore.myqcloud.com/ph-uniapp/apk/MAKUKU.apk';
      }
      location.href = href
    },
  }
}
</script>

<style lang="less" scoped>
.MaKuKuApp {
  height: 100vh;
  background: url("https://tcr-2252e08l-1305473623.cos.ap-shanghai.myqcloud.com/webapp/img/activities/MaKuKuApp_bg.png") no-repeat;
  background-size: 100% 100%;

  .img_box {
    display: flex;
    justify-content: center;

    .img_android {
      margin-top: 0.68rem;
      width: 0.37rem;
      height: 0.42rem;
      text-align: center;
      object-fit: contain;
    }

    .img_ios {
      width: 1.305rem;
      height: 0.335rem;
      margin-top: 0.5rem;
    }
  }

  .btn {
    display: inline-block;
    width: 2.75rem;
    height: 0.45rem;
    background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 100%);
    border-radius: 0.24rem;
    margin: 0.15rem auto 0;
    font-size: 0.18rem;
    font-weight: 500;
    color: #FF7B62;
    text-align: center;
    padding: 0.1rem 0;
  }
}

</style>
